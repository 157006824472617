import _async from "./lib/async";
import _core from "./lib/core";
import _isCore from "./lib/is-core";
import _sync from "./lib/sync";
var exports = {};
var async = _async;
async.core = _core;
async.isCore = _isCore;
async.sync = _sync;
exports = async;
export default exports;